<template>
    <v-container>
      <v-row>
        <v-col>
            <s-signature-pad @save-signature="handleSignature" />
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  
  export default {
    methods: {
      handleSignature(image) {
        console.log("Imagen de la firma:", image);
        const link = document.createElement("a");
        link.href = image;
        link.download = "firma.png";
        link.click();
      },
    },
    data() {
      return {
      };
    },
  };
  </script>